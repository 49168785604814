import React, { Component } from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';

import AddBox from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MomentUtils from '@date-io/moment';
import Autosuggest from 'react-autosuggest';

import { createCustomer } from '../../store/modules/customer/actions';
import {
  setSelectedDate,
  setSearchString,
  getCustomerSuggestions,
  selectCustomerSuggestion,
  clearSearch,
  createBookingsByTemplate,
} from '../../store/modules/view/actions';
import { logout } from '../../store/modules/user/actions';

const autosuggestTheme = {
  container: {
    position: 'relative',
  },

  input: {
    width: '400px',
    height: '30px',
    padding: '16px 20px 16px 40px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    border: '1px solid #aaa',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: fade('#fff', 0.15),
    '&:hover': {
      backgroundColor: fade('#fff', 0.25),
    },
    '&::placeholder': {
      color: 'white',
      fontWeight: '100',
    },
  },

  inputFocused: {
    outline: 'none',
  },

  inputOpen: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },

  suggestionsContainer: {
    display: 'none',
  },

  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: '40px',
    width: '400px',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: '1001',
  },

  suggestionsList: {
    margin: '0',
    padding: 0,
    listStyleType: 'none',
  },

  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
    color: 'black',
  },

  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
}

const styles = (theme) => {
  return {
    grow: {
      flexGrow: 1,
    },

    searchIcon: {
      position: 'absolute',
      top: '6px',
      left: '6px',
      width: '24px',
      height: '24px',
      color: 'white',
    },

    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    picker: {
      maxHeight: '30px;',
      color: 'white',
      border: 'white',
    },

    ...autosuggestTheme,
  };
};

type Props = {
  classes: mixed,
  selectedDate: date,
  searchString: string,
  customerSuggestions: [],
  isDirty: boolean,
  onCreateCustomer: () => void,
  onDateChanged: (date: Object) => void,
  onSearchChanged: (search: string) => void,
  onGetCustomerSuggestions: () => void,
  onSelectCustomerSuggestion: (suggestion: Object) => void,
  onClearSearch: () => void,
  logoutClick: () => void,
  createBookingsByTemplateClick: () => void,
}

class Header extends Component<Props> {
  renderInputComponent = inputProps => (
    <div className="inputContainer">
      <SearchIcon className={inputProps.classes.searchIcon} />
      <input autoFocus {...inputProps} />
    </div>
  );

  render() {
    const {
      classes,
      selectedDate,
      searchString,
      customerSuggestions,
      isDirty,
      onCreateCustomer,
      onDateChanged,
      onSearchChanged,
      onGetCustomerSuggestions,
      onSelectCustomerSuggestion,
      onClearSearch,
      logoutClick,
      createBookingsByTemplateClick,
    } = this.props;

    return (
      <div>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="ru" moment={moment}>
              <div>
                <DatePicker
                  onFocus={event => event.target.blur()}
                  InputProps={{
                    className: classes.picker,
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  format="MMM DD, dddd"
                  value={selectedDate}
                  onChange={onDateChanged}
                />
              </div>
            </MuiPickersUtilsProvider>

            <div style={{ marginLeft: '20px' }}>
              <Autosuggest
                theme={classes} // TODO: passthrough only autosuggest classes
                suggestions={customerSuggestions}
                onSuggestionsFetchRequested={onGetCustomerSuggestions}
                onSuggestionsClearRequested={() => onClearSearch()}
                getSuggestionValue={suggestion => suggestion.name}
                onSuggestionSelected={(e, { suggestion }) => onSelectCustomerSuggestion(suggestion)}
                renderSuggestion={suggestion => (
                  <span>
                    {`${suggestion.phoneNumber} (${suggestion.name})`}
                  </span>
                )}
                inputProps={{
                  value: searchString,
                  onChange: (e) => {
                    return onSearchChanged(e.target.value ? e.target.value : '');
                  },
                  type: 'search',
                  placeholder: 'Введите номер телефона или имя…',
                  classes,
                }}
                renderInputComponent={this.renderInputComponent}
              />
            </div>

            <div style={{ marginLeft: '5px' }}>
              <IconButton onClick={onCreateCustomer} aria-label="delete">
                <AddBox size="large" color="secondary" />
              </IconButton>
            </div>

            <div className={classes.grow} />

            <div className={classes.title}>{process.env.REACT_APP_ENV === 'staging' ? 'ДЕМОВЕРСИЯ' : '' }</div>

            <div>
              {isDirty ?
                <IconButton color="inherit">
                  <WarningIcon />
                </IconButton> : null
              }
            </div>
            
            <div>
              <IconButton color="inherit" onClick={createBookingsByTemplateClick}>
                <ScheduleIcon />
              </IconButton>
            </div>

            <div>
              <IconButton color="inherit" onClick={logoutClick}><ExitToAppIcon /></IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedDate: state.view.selectedDate,
  searchString: state.view.searchString,
  customerSuggestions: state.view.customerSuggestions,
  isDirty: state.view.isDirty,
});

const mapActions = {
  onCreateCustomer: createCustomer,
  onDateChanged: setSelectedDate,
  onSearchChanged: setSearchString,
  onGetCustomerSuggestions: getCustomerSuggestions,
  onSelectCustomerSuggestion: selectCustomerSuggestion,
  onClearSearch: clearSearch,
  logoutClick: logout,
  createBookingsByTemplateClick: createBookingsByTemplate
};

export default compose(
  connect(mapStateToProps, mapActions),
  withStyles(styles),
)(Header);
