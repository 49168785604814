// TODO: clean and refactor
import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { intersection } from 'ramda';
import api from '../../services/apiService';

import * as viewActions from '../../store/modules/view/actions';
import * as editActions from '../../store/modules/edit/actions';
import { showError } from '../../store/modules/app/actions';

import { createEmpty } from '../../common/models/booking';

import { getWorkingDayRange } from '../../utils/helpers/dateUtils';

// todo: refactor and investigate UNDEFINED TABLES CASES
function* validateMove(booking, start, end, newTable) {
  const { view: { bookings } } = yield select();
  const { user: { settings } } = yield select();

  const interval = settings.bookingsInterval * 60000;
  const tableIds = booking.tables.length > 1
    ? booking.tables.map(t => t.id)
    : [newTable];

  const collideItem = bookings.find(
    b => b.id !== booking.id && b.tables
    && intersection(tableIds, b.tables.map(t => t.id)).length !== 0
    && ((b.start - interval < start && b.end + interval > start)
    || (b.start - interval < end && b.end + interval > end)
    || (b.start >= start - interval && b.end <= end + interval)));

  if (collideItem) {
    return false;
  }

  return true;
}


export function* loadBookings() {
  try {
    yield put(editActions.setVisibility({
      preview: false,
      full: false,
      delete: false,
    }));
    yield put(viewActions.setLoading(true));

    const { view: { selectedDate } } = yield select();
    const { user: { settings: { dayRange } } } = yield select();
    const { timeStart, timeEnd } = getWorkingDayRange(selectedDate, dayRange);

    const bookings = yield call(api.booking.getBookings, { from: timeStart, to: timeEnd });

    yield put(viewActions.setBookings(bookings));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(viewActions.setLoading(false));
  }
}

export function* moveBooking({ booking, startTime, endTime, newTable }) {
  if (!(yield validateMove(booking, startTime, endTime, newTable.id))) {
    yield put(showError('Выбранное время занято'));
    return;
  }

  const { edit } = yield select();
  const previousState = edit.currentBooking;

  try {
    yield put(viewActions.setLoading(true));

    yield put(editActions.setFieldValue('start', startTime));
    yield put(editActions.setFieldValue('end', endTime));
    yield put(editActions.setFieldValue('tables',
      edit.currentBooking.tables.length > 1
        ? edit.currentBooking.tables
        : [newTable]));
    yield put(editActions.setFieldValue('primaryTable', newTable.id));

    const { edit: { currentBooking } } = yield select();
    yield put(viewActions.updateBooking(currentBooking));

    const result = yield call(api.booking.updateBooking, { booking: currentBooking });
    yield put(editActions.setCurrentBooking(result));
  } catch (err) {
    yield put(viewActions.updateBooking(previousState));
    yield put(editActions.setCurrentBooking(previousState));

    yield put(showError(err.message));
  } finally {
    yield put(viewActions.setLoading(false));
  }
}

export function* createBooking({ time, table }) {
  if (new Date(time) >= new Date()) {
    const startTime = time;
    const endTime = moment(time).add(2, 'hour').valueOf(); // todo: move to empty initializer

    yield put(editActions.setCurrentBooking(
      createEmpty({ tables: [table], startTime, endTime }),
    ));
    yield put(editActions.setVisibility({
      preview: false,
      full: true,
      delete: false,
    }));
  }
}

export function* createBookingsByTemplate(date) {
  try {
    yield put(viewActions.setLoading(true));

    const { view: { selectedDate } } = yield select();

    yield call(api.booking.createBookingsByTemplate, { currentDate: selectedDate });
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(viewActions.setLoading(false));
    yield put(viewActions.loadBookings());
  }
}

const getBookings = state => state.view.bookings;

export function* setBookingDirty(bookingId) {
  const bookings = yield select(getBookings);
  const booking = bookings.find((e) => e.id === bookingId);
  if (booking) {
    booking.isDirty = true;
  }
  yield put(viewActions.updateBooking(booking));
}
